import React from 'react';
import { Navigate } from 'react-router-dom';

interface IProps {
    path: string;
    element: React.ReactElement;
    redirect?: string;
    isAuthenticated: boolean;

    onRedirectAction: (path: string) => void;
}

export const ProtectedRoute: React.FC<IProps> = ({
    path,
    redirect = '/',
    isAuthenticated,
    onRedirectAction,
    element,
    // children,
}) => {
    if (!isAuthenticated) {
        onRedirectAction(path);

        return <Navigate to={redirect} replace />;
    }

    // if (children) {
    //     return children.map((nestedComponent) => (
    //         <Route
    //             key={nestedComponent.path}
    //             path={nestedComponent.path}
    //             element={nestedComponent.element}
    //         />
    //     ));
    // }

    return element;
};
