import { type ITitanAnalyticsEvent, type TDefaultTitanUIParameters } from 'src/@types';
import { projectPages } from 'src/constants/project.constants';
import { v4 as uuidv4 } from 'uuid';

import { Titan } from '../configs/web-sdk.config';

// Helpers

const defaultTitanUIParameters: TDefaultTitanUIParameters = (otherParameters = {}) => {
    const pathname = window.location.pathname;

    return {
        screen_name: projectPages[pathname],
        index: 1,
        ...otherParameters,
    };
};

const triggerDDEvent = () => {
    window.DD_RUM?.addAction('titan-events-count');
};

// Main events

export const titanAnalyticsEvents: ITitanAnalyticsEvent = {
    // UI events

    appOpen: () => {
        const isWarmStart = window.history.length === 1;

        Titan.SendEvent({
            name: 'sys.app_open',
            parameters: {
                is_warm_start: isWarmStart,
            },
        });

        triggerDDEvent();
    },
    generatePageLoadEvent: (pageName) => {
        Titan.SendEvent({
            name: 'sys.ui',
            parameters: {
                ...defaultTitanUIParameters(),
                screen_id: `page_${pageName}_${uuidv4()}`,
                screen_type: 'screen',
                screen_category: 'page',
                interaction_type: 'load',
                interaction_object: `${pageName}_page`,
            },
        });

        triggerDDEvent();
    },
    generateButtonEvent: ({ screen_id, screen_category, interaction_object }) => {
        Titan.SendEvent({
            name: 'sys.ui',
            parameters: {
                ...defaultTitanUIParameters(),
                screen_id: `${screen_id}_button_${uuidv4()}`,
                screen_type: 'screen',
                screen_category,
                interaction_type: 'tap',
                interaction_object,
            },
        });

        triggerDDEvent();
    },
    generateModalEvent: ({
        screen_id,
        screen_id_helper = false,
        screen_category,
        interaction_object,
    }) => {
        let screenId = `${screen_id}_modal_${uuidv4()}`;

        if (screen_id_helper) {
            screenId += `_${screen_id_helper}`;
        }

        Titan.SendEvent({
            name: 'sys.ui',
            parameters: {
                ...defaultTitanUIParameters(),
                screen_id: screenId,
                screen_type: 'pop-up',
                screen_category,
                interaction_type: 'load',
                interaction_object,
            },
        });

        triggerDDEvent();
    },
    generatePopoverEvent: ({ screen_id, screen_category, interaction_object }) => {
        Titan.SendEvent({
            name: 'sys.ui',
            parameters: {
                ...defaultTitanUIParameters(),
                screen_id: `${screen_id}_popover_${uuidv4()}`,
                screen_type: 'pop-up',
                screen_category,
                interaction_type: 'load',
                interaction_object,
            },
        });

        triggerDDEvent();
    },
    generatePopUpEvent: ({
        screen_id,
        screen_id_helper = false,
        screen_category,
        interaction_type = 'load',
        interaction_object,
    }) => {
        let screenId = `${screen_id}_pop-up_${uuidv4()}`;

        if (screen_id_helper) {
            screenId += `_${screen_id_helper}`;
        }

        Titan.SendEvent({
            name: 'sys.ui',
            parameters: {
                ...defaultTitanUIParameters(),
                screen_id: screenId,
                screen_type: 'pop-up',
                screen_category,
                interaction_type,
                interaction_object,
            },
        });

        triggerDDEvent();
    },
    generateChangeEvent: ({ screen_id, screen_category, interaction_object }) => {
        Titan.SendEvent({
            name: 'sys.ui',
            parameters: {
                ...defaultTitanUIParameters(),
                screen_id: `${screen_id}_change_${uuidv4()}`,
                screen_type: 'screen',
                screen_category,
                interaction_type: 'change',
                interaction_object,
            },
        });

        triggerDDEvent();
    },

    // Xsolla events
    // TODO start generating events after discussion with PM
    xsolla_payment: ({
        error,
        amount_us,
        amount_local_currency,
        local_currency_type,
        store_sku,
        special_id,
    }) => {
        const parameters: { [key: string]: string | number | boolean } = {
            amount_us,
            amount_local_currency,
            local_currency_type,
            store_sku,
            special_id,
            success: true,
        };

        if (error) {
            parameters.error = error;
            parameters.success = false;
        }

        Titan.SendEvent({
            name: 'sys_payment',
            parameters,
        });

        triggerDDEvent();
    },
    xsolla_transaction: ({ context, item_id, quantity }) => {
        Titan.SendEvent({
            name: 'sys_payment',
            parameters: {
                trans_type: 'web_portal_iap',
                context,
                item_id,
                quantity,
            },
        });

        triggerDDEvent();
    },
};
