import React, { Suspense, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { IS_DEVELOPMENT } from 'src/constants';
import { pageMaintenanceModeRenderer } from 'src/helpers/utils';
import { useAppDispatch } from 'src/store/configureStore';
import { globalOp, globalSel } from 'src/store/global';
import Layout from 'src/views/layout/Layout';
import Maintenance from 'src/views/pages/Maintenance/Maintenance';
import NotFoundPage from 'src/views/pages/NotFound/NotFound';

import { ProtectedRoute } from './ProtectedRoute';
import { routes } from './routes';

const AppRouter = () => {
    const dispatch = useAppDispatch();

    const authenticated = useSelector(globalSel.isAuthSelector);
    const killSwitchConfig = useSelector(globalSel.killSwitchConfig)?.value;

    const [killSwitchLoaded, setKillSwitchLoaded] = useState(false);
    const [pages, setPages] = useState(routes);

    const enableMaintenanceMode =
        killSwitchConfig &&
        !killSwitchConfig.global &&
        typeof killSwitchConfig.global !== 'undefined';

    const handleOpenModal = (path: string) => {
        dispatch(
            globalOp.handleAuthenticatedModalToggle({
                open: true,
                type: path === '/events' ? 'eventsAuth' : 'storeAuth',
            }),
        );
    };

    const maintenanceWrapper = (route: any) => {
        return pageMaintenanceModeRenderer(route.path, killSwitchConfig) ? (
            <Maintenance />
        ) : (
            <route.component />
        );
    };

    useEffect(() => {
        dispatch(globalOp.getConfigs()).finally(() => {
            setKillSwitchLoaded(true);
        });

        if (!IS_DEVELOPMENT) {
            setPages(routes.filter((route) => !route.testing));
        }
    }, []);

    if (!killSwitchLoaded) {
        return null;
    }

    if (enableMaintenanceMode) {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Maintenance fullPage />} />
                </Routes>
            </BrowserRouter>
        );
    }

    return (
        <BrowserRouter>
            <Layout>
                <Suspense fallback={null}>
                    <Routes>
                        {pages.map((route) =>
                            route.private ? (
                                <Route
                                    key={route.path}
                                    path={route.path}
                                    element={
                                        <ProtectedRoute
                                            key={route.path}
                                            path={route.path}
                                            redirect={route.redirect}
                                            isAuthenticated={authenticated}
                                            onRedirectAction={handleOpenModal}
                                            element={maintenanceWrapper(route)}
                                        />
                                    }
                                />
                            ) : (
                                <Route
                                    key={route.path}
                                    path={route.path}
                                    element={maintenanceWrapper(route)}
                                >
                                    {route.children?.map((nestedComponent) => (
                                        <Route
                                            key={nestedComponent.path}
                                            path={nestedComponent.path}
                                            element={maintenanceWrapper(route)}
                                        />
                                    ))}
                                </Route>
                            ),
                        )}

                        <Route element={<NotFoundPage />} />
                    </Routes>
                </Suspense>
            </Layout>
        </BrowserRouter>
    );
};

export default AppRouter;
