import React from 'react';
import { useSelector } from 'react-redux';
import { titanAnalyticsEvents } from 'src/helpers/titanAnalytics';
import { useTranslation } from 'src/hooks';
import { useAppDispatch } from 'src/store/configureStore';
import { globalSel } from 'src/store/global';
import { Box } from 'src/views/components/ui-components';

import { NOTIFICATIONS_STATES } from '../../../../../constants/notifications.constants';
import {
    isNotificationEnabled,
    isNotificationsBlockedByBrowser,
} from '../../../../../helpers/pushNotification';
import { notificationsOp, notificationsSel } from '../../../../../store/notifications';
import NotificationsWarningMessage from '../../../NotificationsWarningMessage/NotificationsWarningMessage';
import SwitchContainer from '../../SwitchContainer';

const ToggleNotificationsSwitcher = () => {
    const dispatch = useAppDispatch();
    const t = useTranslation();

    const notificationsState = useSelector(notificationsSel.notificationsStateSelector);
    const currentUser = useSelector(globalSel.currentUserSelector);
    const { palette } = useSelector(globalSel.themeSelector);

    const handleChangeNotificationsSwitcher = (enable) => {
        const titanEventText = enable ? 'enable' : 'disabled';

        titanAnalyticsEvents.generateButtonEvent({
            screen_id: `notifications_switcher_${titanEventText}_notifications`,
            screen_category: 'notifications',
            interaction_object: 'notifications_switcher',
        });

        dispatch(
            notificationsOp.setNotificationsState(
                enable
                    ? NOTIFICATIONS_STATES.ENABLED
                    : NOTIFICATIONS_STATES.BLOCKED_FROM_CONTROLLER,
                currentUser?.accountId,
            ),
        );
    };

    return (
        <>
            <SwitchContainer
                title={t('common_allow_notifications_text', 'Allow Notifications')}
                typographyAttributes={{
                    variant: 'p14',
                    color: palette.mode === 'dark' ? palette.grey.light[400] : '#8A8E99',
                }}
                data-testId="notifications_switcher"
                onChange={handleChangeNotificationsSwitcher}
                checked={isNotificationEnabled(notificationsState)}
                disabled={isNotificationsBlockedByBrowser(notificationsState)}
            />

            {isNotificationsBlockedByBrowser(notificationsState) && (
                <Box mt={4}>
                    <NotificationsWarningMessage notificationsState={notificationsState} />
                </Box>
            )}
        </>
    );
};

export default ToggleNotificationsSwitcher;
