import BlueFrame from '../assets/images/offerItemFrames/blue-frame.png';
import DefaultFrame from '../assets/images/offerItemFrames/default-frame.png';
import GreenFrame from '../assets/images/offerItemFrames/green-frame.png';
import PurpleFrame from '../assets/images/offerItemFrames/purple-frame.png';

export const getColorStyle = (str = '', type = 'border', borderWidth = 1) => {
    return str.includes('gradient')
        ? `${type}-image: ${str} ${borderWidth}`
        : `${type}-color: ${str}`;
};

export const toggleBodyOverflow = (hide: boolean) => {
    const bodyElementStyles = document.getElementsByTagName('body')[0]!.style;

    if (hide) {
        bodyElementStyles.overflow = 'hidden';
        bodyElementStyles.touchAction = 'none';
        return;
    }

    bodyElementStyles.overflow = 'auto';
    bodyElementStyles.touchAction = 'auto';
};

export const rarityMapper = (value = 1) => {
    switch (value) {
        case 1:
            return {
                color: '#4D5156',
                frame: DefaultFrame,
                newColor: '#4D5156',
            };
        case 2:
            return {
                color: '#1FAA66',
                frame: GreenFrame,
                newColor: '#0F5E38',
            };
        case 3:
            return {
                color: '#2594CA',
                frame: BlueFrame,
                newColor: '#1C3E6E',
            };
        case 4:
            return {
                color: '#8858D1',
                frame: PurpleFrame,
                newColor: '#402A62',
            };
        default:
            return {
                color: '#4D5156',
                frame: DefaultFrame,
                newColor: '#4D5156',
            };
    }
};

export const transitions = (...args: string[]) => {
    return args.map((property) => `${property} 0.3s cubic-bezier(0.29, 0.86, 1, 1)`).join(', ');
};

export const toggleElement = (show: boolean = false) => {
    return `
        opacity: ${show ? 1 : 0};
        visibility: ${show ? 'visible' : 'hidden'};
        pointer-events: ${show ? 'all' : 'none'};
    `;
};

export const textGradient = (gradientColors: string, fallbackColor: string = 'white') => `
    @supports not (-webkit-touch-callout: none) {
        background: linear-gradient(${gradientColors});
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    @supports (-webkit-touch-callout: none) {
        color: ${fallbackColor};
    }
`;

export const textCutX = (): string => {
    return `
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    `;
};

export const textCutY = (lineCount: number) => {
    return `
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: ${lineCount};
        -webkit-box-orient: vertical;
    `;
};

export function hexToRgba(hex: string, opacity?: number) {
    if (/^#([0-9a-f]{3}){1,2}$/i.test(hex)) {
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function (m, r, g, b) {
            return r + r + g + g + b + b;
        });

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
            ? opacity !== undefined
                ? `rgba(${parseInt(result[1] as string, 16)}, ${parseInt(
                      result[2] as string,
                      16,
                  )}, ${parseInt(result[3] as string, 16)}, ${opacity})`
                : `rgb(${parseInt(result[1] as string, 16)}, ${parseInt(
                      result[2] as string,
                      16,
                  )}, ${parseInt(result[3] as string, 16)})`
            : '';
    }
    return '';
}

export const toggleScrollBar = ({ isDark = true, enable = true }) => {
    if (!enable) {
        return `
            &::-webkit-scrollbar {
                display: none;
                width: 0;
            }
        `;
    }

    return `
        &::-webkit-scrollbar {
            display: block;
            width: 4px;
        }
        &::-webkit-scrollbar-track {
            background-color: ${isDark ? '#333333' : '#ebebeb'} ;
        }
        &::-webkit-scrollbar-thumb {
            background-color: ${isDark ? '#464a4d' : '#b8becc'} ;
        }
    `;
};

export const tooltipArrowConfiger = (
    direction = 'top',
    offset = '-4px',
    position: string | undefined,
) => {
    switch (direction) {
        case 'top':
            return {
                transform: 'transform: rotate(45deg)',
                offset: `top: ${offset}`,
                position: `left: ${position || '89%'}`,
            };
        case 'bottom':
            return {
                transform: 'transform: rotate(45deg)',
                offset: `bottom: ${offset}`,
                position: `left: ${position || '89%'}`,
            };
        case 'left':
            return {
                transform: 'transform: rotate(-45deg)',
                offset: `left: ${offset}`,
                position: `top: ${position || '50%'}`,
            };
        case 'right':
            return {
                transform: 'transform: rotate(135deg)',
                offset: `right: ${offset}`,
                position: `top: ${position || '50%'}`,
            };
        default:
            return {
                transform: 'transform: rotate(45deg)',
                offset: `top: ${offset}`,
                position: `left: ${position || '89%'}`,
            };
    }
};

/**
 * Converts an RGB color value to HSL.
 * @param r - The red color value (0-255).
 * @param g - The green color value (0-255).
 * @param b - The blue color value (0-255).
 * @returns A tuple representing the HSL values:
 *   - h: Hue (0-360)
 *   - s: Saturation (0-100)
 *   - l: Lightness (0-100)
 */
export const rgbToHsl = (r: number, g: number, b: number): [number, number, number] => {
    // Normalize red, green, blue values to the range 0-1
    r /= 255;
    g /= 255;
    b /= 255;

    // Find the maximum and minimum values among the normalized RGB components
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);

    // Initialize hue (h), saturation (s) and lightness (l)
    let h = 0;
    let s = 0;
    const l = (max + min) / 2; // Lightness is the average of the max and min values

    // If the max and min values are not equal, calculate the hue and saturation
    if (max !== min) {
        const d = max - min; // Difference between max and min values
        // Saturation calculation based on the lightness
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

        // Determine hue based on which RGB component is the max value
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
            default:
                h = 0; // Default case, which is technically unreachable
        }

        h /= 6; // Normalize hue to the range 0-1
    }

    // Return the HSL values
    // Hue (h) is converted to degrees (0-360)
    // Saturation (s) and Lightness (l) are converted to percentages (0-100)
    return [h * 360, s * 100, l * 100];
};

export const lightenHsl = (h: number, s: number, l: number, amount: number): string => {
    return `hsl(${h}, ${s}%, ${Math.min(l + amount, 100)}%)`;
};

/**
 * Lightens an RGB color string by a specified amount.
 * @param rgb - The RGB color string in the format 'rgb(r, g, b)'.
 * @param lightenAmount - The amount to lighten the color by (percentage).
 * @returns The lightened RGB color string in the same format.
 * @throws Will throw an error if the input RGB format is invalid or if the conversion fails.
 */
export const lightenRgbColor = (rgb: string, lightenAmount: number): string => {
    // Extract numeric RGB values from the input string
    const rgbValues = rgb.match(/\d+/g)?.map(Number);

    // Validate extracted RGB values
    if (!rgbValues || rgbValues.length !== 3) {
        throw new Error('Invalid RGB format');
    }

    // Destructure RGB values
    const [r, g, b] = rgbValues;

    // Ensure RGB values are numbers within the expected range
    if (
        r === undefined ||
        g === undefined ||
        b === undefined ||
        r < 0 ||
        r > 255 ||
        g < 0 ||
        g > 255 ||
        b < 0 ||
        b > 255
    ) {
        throw new Error('RGB values must be numbers between 0 and 255');
    }

    // Convert RGB to HSL
    const [h, s, l] = rgbToHsl(r, g, b);

    // Validate HSL conversion
    if (h === undefined || s === undefined || l === undefined) {
        throw new Error('HSL conversion failed');
    }

    // Lighten the HSL color by a specified amount
    return lightenHsl(h, s, l, lightenAmount);
};
