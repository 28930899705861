import React from 'react';
import { type Theme, useMediaQuery } from '@mui/material';
import { hexToRgba } from 'src/helpers/styleHelpers';
import { type DefaultTheme } from 'styled-components';

import { AUTOMATION_TESTING_IDS } from '../../../../constants';
import { useTranslation } from '../../../../hooks';
import BorderedContent from '../../BorderedContent/BorderedContent';
import { Box } from '../../ui-components/Box/Box';
import { Icon } from '../../ui-components/Icon/Icon';
import { Typography } from '../../ui-components/Typography/Typography';
import { SnackBarStyled } from '../SnackBar.styled';

interface IProps {
    messageTranslationKey: string;
    message: string;
    isTimeOutDisabled?: boolean;
    open: boolean;
    theme: DefaultTheme;
    onClose?: () => void;
    variant: 'success' | 'error' | 'warning' | 'info';
}

const COLORS_BY_VARIANT = {
    success: '#D3F5E0',
    error: '#F3E0E0',
    info: '#CAEAFB',
    warning: '#FAEEC9',
};

const ICON_COLORS_BY_VARIANT = {
    success: '#2AAE2A',
    error: '#B41F30',
    info: '#0E7EDD',
    warning: '#F0A10E',
};

const SnackbarComponent: React.FC<IProps> = ({
    messageTranslationKey,
    message,
    isTimeOutDisabled,
    open,
    theme,
    onClose = () => {},
    variant,
}) => {
    const t = useTranslation();
    const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up?.('sm'));

    return (
        <Box
            component={SnackBarStyled}
            className={isTimeOutDisabled ? 'WP-Snackbar-without-timeout' : ''}
            open={open}
            display={open ? 'block' : 'none'}
            position="absolute"
            top={[30, 48]}
            width={[310, 350]}
        >
            <BorderedContent
                hasBlur
                p={1}
                innerBgColor="#292B2E"
                outerBorder={hexToRgba(theme.palette.common.white, 0.2)}
            >
                <Box
                    bgcolor="#525459"
                    p={2}
                    pr={3}
                    pt={2.5}
                    position="relative"
                    id={AUTOMATION_TESTING_IDS.notificationContent}
                >
                    <Box position="relative" zIndex={3} display="flex" alignItems="flex-start">
                        <Box
                            bgcolor={COLORS_BY_VARIANT[variant]}
                            borderRadius="50%"
                            p={2}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Icon
                                size={smUp ? 24 : 20}
                                color={ICON_COLORS_BY_VARIANT[variant]}
                                className={`icon-${variant}`}
                            />
                        </Box>
                        <Box
                            component={Typography}
                            color="#E2E6EF"
                            variant={['p14', 'p16']}
                            width={1}
                            px={3}
                        >
                            {t(messageTranslationKey, message)}
                        </Box>
                        <Icon
                            color="#BAC1CC"
                            size={13}
                            className="icon-close cursor-pointer"
                            onClick={onClose}
                            id={AUTOMATION_TESTING_IDS.notificationCloseButton}
                        />
                    </Box>
                </Box>
            </BorderedContent>
        </Box>
    );
};

export default SnackbarComponent;
