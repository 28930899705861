import { getSessionToken } from '../bridge';
import { GA4_MEASUREMENT_ID } from '../constants';
import { projectPages, USER_DATA_SESSION_STORAGE_KEY } from '../constants/project.constants';
import { getSessionStorage } from '../services';

type TEventMethod = {
    action: string;
    event_category: string;
    event_label: string;
    rest?: { [key: string]: string };
};

type TAppLoadEvent = {
    pagePathName: string;
};

type TPageLoadEvent = TAppLoadEvent & {
    pagePathName: string;
    previousPath: string;
};

type TGoogleAnalyticsEvent = {
    appLoad: (args: TAppLoadEvent) => void;

    pageLoadEvent: (args: TPageLoadEvent) => void;

    event: (arg0: TEventMethod) => void;
};

export const googleAnalyticsEvent: TGoogleAnalyticsEvent = {
    appLoad: ({ pagePathName }) => {
        if (!window.gtag) {
            return;
        }

        return window.gtag('config', GA4_MEASUREMENT_ID as string, {
            page_title: pagePathName,
            page_path: pagePathName,
        });
    },
    pageLoadEvent: ({ pagePathName, previousPath }) => {
        if (!window.gtag) {
            return;
        }

        const checkForNotFoundPath = projectPages[pagePathName] || projectPages['/not-found'];

        return window.gtag('event', `${checkForNotFoundPath}/viewed`, {
            event_category: 'page_view',
            event_label: `${checkForNotFoundPath}_page_view`,
            previous_path: previousPath,
            is_scopelyID_loggedin: Boolean(getSessionToken()),
            user_id: getSessionStorage(USER_DATA_SESSION_STORAGE_KEY)?.accountId,
        });
    },
    event: ({ action, event_category, event_label, ...rest }) => {
        if (!window.gtag) {
            return;
        }

        return window.gtag('event', action, {
            event_category,
            event_label,
            is_scopelyID_loggedin: Boolean(getSessionToken()),
            user_id: getSessionStorage(USER_DATA_SESSION_STORAGE_KEY)?.accountId,
            ...rest,
        });
    },
};
