import React from 'react';
import { ClickAwayListener, Popper } from '@mui/material';

import { Box } from '../Box/Box';

interface PopperContainerProps {
    anchorEl: HTMLAnchorElement | null;
    children: React.ReactNode;

    setAnchorEl: (args: HTMLAnchorElement | null) => void;
    clickAwayEnabled: boolean;
    zIndex: number;
    width?: number | string;
    modifiers: object;
    disablePortal: boolean;
    placement: string;
}

const defaultModifiers = {
    flip: {
        enabled: true,
    },
    hide: {
        enabled: false,
    },
    preventOverflow: {
        enabled: false,
    },
};

const PopperContainer: React.FC<PopperContainerProps> = ({
    children,
    anchorEl = null,
    setAnchorEl = () => {},
    clickAwayEnabled = false,
    zIndex = 3,
    width = 1,
    modifiers = defaultModifiers,
    disablePortal = true,
    placement = 'bottom-start',
}) => {
    const handleClickAway = () => {
        clickAwayEnabled && setAnchorEl(null);
    };

    const id = anchorEl ? 'WP-PopperContainer' : undefined;

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div data-testid="WP-PopperContainer">
                <Box
                    component={Popper}
                    zIndex={zIndex}
                    width={width}
                    id={id}
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    disablePortal={disablePortal}
                    placement={placement}
                    modifiers={modifiers}
                >
                    {children}
                </Box>
            </div>
        </ClickAwayListener>
    );
};

export default PopperContainer;
