import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import withTestingProperties, { type ITestingProperties } from 'src/hoc/withTestingProperties';

import { useWindowWidth } from '../../../../hooks';
import { globalSel } from '../../../../store/global';
import { TypographyStyled } from './Typography.styled';

interface TypographyProps {
    variant?: string | string[];
    fWeight?: 'bold' | 'semiBold' | 'medium' | 'regular' | 'book';
    color?: string;
    textDecoration?: string;
    fontFamily?: 'architectsDaughter' | 'fingerPaint' | 'Inter' | 'univiaPro';
    className?: string;
    children?: React.ReactNode;
    testingProperties?: ITestingProperties;
}

type TTypographyVariant = 'p' | 'pre' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

const Typography: React.FC<TypographyProps> = ({
    variant = 'p12',
    fWeight = 'regular',
    color = '',
    fontFamily = 'Inter',
    textDecoration,
    children,
    className = '',
    testingProperties,
}) => {
    const theme = useSelector(globalSel.themeSelector);
    const windowWidth = useWindowWidth();

    const breakPoints = useMemo(() => {
        return [
            windowWidth > 0 && windowWidth < theme.breakpoints.values.sm,
            windowWidth > 0 &&
                windowWidth >= theme.breakpoints.values.sm &&
                windowWidth < theme.breakpoints.values.md,
            windowWidth > 0 &&
                windowWidth >= theme.breakpoints.values.md &&
                windowWidth < theme.breakpoints.values.lg,
            windowWidth > 0 &&
                windowWidth >= theme.breakpoints.values.lg &&
                windowWidth < theme.breakpoints.values.xl,
            windowWidth > 0 && windowWidth >= theme.breakpoints.values.xl,
        ];
    }, [windowWidth]);

    const responsiveVariant = useMemo(() => {
        if (Array.isArray(variant)) {
            if (variant.length === 1) {
                return variant[0] || 'p12';
            }

            const responsiveIndex = breakPoints.findIndex((i) => i);
            const currentVariant = variant[responsiveIndex];

            if (currentVariant) {
                return currentVariant;
            }

            return variant[variant.length - 1] || 'p12';
        }

        return variant;
    }, [breakPoints, variant]);

    const typographyEl: TTypographyVariant = useMemo(() => {
        if (responsiveVariant.match('/p[1-9]/')) {
            return 'p';
        }

        if (responsiveVariant.match('/h[1-6]/')) {
            return responsiveVariant as TTypographyVariant;
        }

        if (responsiveVariant.includes('pre')) {
            return 'pre';
        }

        return 'p';
    }, [responsiveVariant]);

    return (
        <TypographyStyled
            {...testingProperties}
            as={typographyEl}
            className={clsx(responsiveVariant, fWeight, fontFamily, textDecoration, className)}
            color={color}
            {...testingProperties}
        >
            {children}
        </TypographyStyled>
    );
};

const TypographyWC = withTestingProperties(Typography);

export { TypographyWC as Typography };
