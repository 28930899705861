import { useSelector } from 'react-redux';

import { translationsSel } from '../store/translation';

const generateTemplateString = (function () {
    const cache = {};

    function generateTemplate(template: string) {
        let fn = cache[template];
        if (!fn) {
            // Replace ${expressions} (etc) with ${map.expressions}.

            const sanitized = template
                .replace(/\$\{(\s*[^;\s{]+\s*)\}/g, function (_, match) {
                    return `$\{map.${match.trim()}}`;
                })
                // Afterwards, replace anything that's not ${map.expressions}' (etc) with a blank string.
                .replace(/(\$\{(?!map\.)[^}]+\})/g, '');

            fn = Function('map', `return \`${sanitized}\``);
        }

        return fn;
    }

    return generateTemplate;
})();

export const useTranslation = () => {
    const translations = useSelector(translationsSel.translationsSelector);
    const selectedLang = useSelector(translationsSel.selectedLanguageSelector);

    return (key: string, fallbackText = '', dynamicVariables?: { [variable: string]: any }) => {
        const hasTranslation = translations[key];

        if (hasTranslation) {
            let translationText = '';
            if (hasTranslation[selectedLang]) {
                translationText = hasTranslation[selectedLang];
            } else {
                translationText = hasTranslation['en'];
            }

            if (dynamicVariables && Object.keys(dynamicVariables).length !== 0 && translationText) {
                const generatedString = generateTemplateString(translationText);
                translationText = generatedString(dynamicVariables);
            }

            if (translationText) return translationText;
        }

        return fallbackText;
    };
};

export const useTranslationByKey = () => {
    const translations = useSelector(translationsSel.translationsSelector);
    const selectedLang = useSelector(translationsSel.selectedLanguageSelector);

    return (key: string) => {
        const hasTranslation = translations[key];

        if (hasTranslation) {
            const hasTranslationWithCurrentLanguage = translations[key][selectedLang];

            if (!hasTranslationWithCurrentLanguage && !translations[key]['en']) {
                return '';
            }

            if (hasTranslationWithCurrentLanguage) {
                return hasTranslationWithCurrentLanguage;
            }

            return translations[key]['en'];
        }

        return key;
    };
};
