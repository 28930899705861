import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ClickAwayListener, Portal } from '@mui/material';
import PropTypes from 'prop-types';
import { projectPages } from 'src/constants/project.constants';
import { hexToRgba, toggleBodyOverflow } from 'src/helpers/styleHelpers';
import { getMobileOperatingSystem } from 'src/helpers/userDeviceHelpers';
import { getUserSelectedLanguage } from 'src/helpers/webEnvHelpers';

import arrowDown from '../../../../../assets/images/arrow-down.png';
import check from '../../../../../assets/images/check.png';
import de from '../../../../../assets/images/languagesFlags/de.png';
import es from '../../../../../assets/images/languagesFlags/es.png';
import fr from '../../../../../assets/images/languagesFlags/fr.png';
import it from '../../../../../assets/images/languagesFlags/it.png';
import ja from '../../../../../assets/images/languagesFlags/ja.png';
import kr from '../../../../../assets/images/languagesFlags/kr.png';
import pt from '../../../../../assets/images/languagesFlags/pt.png';
import ru from '../../../../../assets/images/languagesFlags/ru.png';
import us from '../../../../../assets/images/languagesFlags/us.png';
import { AUTOMATION_TESTING_IDS, LANGUAGE_STORAGE_KEY } from '../../../../../constants';
import { titanAnalyticsEvents } from '../../../../../helpers/titanAnalytics';
import { useTranslation } from '../../../../../hooks';
import { setLocalStorage } from '../../../../../services';
import { handleError } from '../../../../../services/handleError.service';
import { globalSel } from '../../../../../store/global';
import { translationsOp } from '../../../../../store/translation';
import { Box } from '../../../../components/ui-components/Box/Box';
import PopperContainer from '../../../../components/ui-components/PopperContainer/PopperContainer';
import { Typography } from '../../../../components/ui-components/Typography/Typography';
import { LanguageDropdownStyled, LanguageItemDropdownStyled } from './LanguageDropdown.styled';

const languages = [
    {
        key: 'en',
        name: 'English',
        flag: us,
    },
    {
        key: 'fr',
        name: 'Français',
        flag: fr,
    },
    {
        key: 'it',
        name: 'Italiano',
        flag: it,
    },
    {
        key: 'de',
        name: 'Deutsch',
        flag: de,
    },
    {
        key: 'es',
        name: 'Español',
        flag: es,
    },
    {
        key: 'ru',
        name: 'Русский',
        flag: ru,
    },
    {
        key: 'pt',
        name: 'Português',
        flag: pt,
    },
    {
        key: 'ja',
        name: '日本語',
        flag: ja,
    },
    {
        key: 'ko',
        name: '한국어',
        flag: kr,
    },
];

const LanguagesList = ({ theme, selectedLanguageKey, onSelect }) => {
    return languages.map((language, index) => {
        const selected = language.key === selectedLanguageKey;

        return (
            <Box
                component={LanguageItemDropdownStyled}
                selected={selected}
                key={language.key}
                display="flex"
                mx={3}
                mt={3}
                justifyContent="space-between"
                alignItems="center"
                border={0}
                onClick={() => onSelect(language)}
                mb={index === languages.length - 1 ? 3 : 0}
                name={`${AUTOMATION_TESTING_IDS.languageSelectOption}-${language.key}`}
            >
                <Box py={2} width={1} display="flex" alignItems="center">
                    <Box height={20} width={20} ml={2}>
                        <Box
                            component="img"
                            src={language.flag}
                            width={1}
                            height={1}
                            alt="flag"
                            className="WP-LanguageDrop-item-flag"
                        />
                    </Box>

                    <Box
                        component={Typography}
                        fontFamily={theme.typography.font.inter}
                        variant="p14"
                        color={
                            selected && theme.palette.mode === 'light'
                                ? theme.palette.lightMode.grey[900]
                                : '#8b919b'
                        }
                        ml={3}
                    >
                        {language.name}
                    </Box>

                    {selected && (
                        <Box
                            display="flex"
                            alignItems="center"
                            ml="auto"
                            mr={3}
                            width={11}
                            height={8}
                            className="check-img"
                        >
                            <img src={check} alt="check" />
                        </Box>
                    )}
                </Box>
            </Box>
        );
    });
};

const LanguageDropdown = ({
    setIsTouchDeviceLangDropdownOpen,
    onLeftSideBarClose,
    isTouchDeviceLangDropdownOpen,
}) => {
    const { getTranslations, setLanguage, setTranslations, setTranslationLoading } = translationsOp;
    const dispatch = useDispatch();
    const t = useTranslation();
    const theme = useSelector(globalSel.themeSelector);

    const location = useLocation();

    const [selectedLanguage, setSelectedLanguage] = useState(
        languages.find((language) => language.key === getUserSelectedLanguage()),
    );
    const [anchorEl, setAnchorEl] = useState(null);

    const isMobile = getMobileOperatingSystem() !== 'unknown';
    const isDark = theme.palette.mode === 'dark';

    const handleLanguageSelect = (selectedLanguage) => {
        setLocalStorage(LANGUAGE_STORAGE_KEY, selectedLanguage.key);
        setSelectedLanguage(selectedLanguage);

        if (isMobile) {
            setIsTouchDeviceLangDropdownOpen(false);
        }

        titanAnalyticsEvents.generateButtonEvent({
            screen_id: `${selectedLanguage.name.toLowerCase()}_language_select`,
            screen_category: 'language',
            interaction_object: 'language_selection',
        });

        let currentPage = projectPages[location.pathname] || projectPages['/'];
        const currentLoadingPage = currentPage;

        if (currentPage === 'dashboard') {
            currentPage = 'home';
        }

        // Set all translations to loading state
        Object.values(projectPages).forEach((page) => {
            dispatch(setTranslationLoading(page, true));
        });

        handleError(
            getTranslations(['common', currentPage])
                .then((res) => {
                    if (Array.isArray(res)) {
                        res.forEach((r) => {
                            dispatch(setTranslations(r.data));
                        });
                    }
                })
                .then(() => {
                    document.documentElement.setAttribute('lang', selectedLanguage.key);

                    dispatch(setLanguage(selectedLanguage.key));
                })
                .finally(() => {
                    dispatch(setTranslationLoading('common', false));
                    dispatch(setTranslationLoading(currentLoadingPage, false));
                }),
        );
    };

    const selfClickHandler = (e) => {
        if (isMobile) {
            if (isTouchDeviceLangDropdownOpen) {
                return;
            }
            return setIsTouchDeviceLangDropdownOpen(!isTouchDeviceLangDropdownOpen);
        }
        setAnchorEl(anchorEl ? null : e.currentTarget);
    };

    const clickAwayHandler = () => {
        if (isMobile) {
            setIsTouchDeviceLangDropdownOpen(false);
        }
        setAnchorEl(null);
    };

    const handleCloseMobileDropDown = () => {
        onLeftSideBarClose(false);
        setIsTouchDeviceLangDropdownOpen(false);
    };

    useEffect(() => {
        if (isTouchDeviceLangDropdownOpen) {
            return toggleBodyOverflow(true);
        }

        toggleBodyOverflow(false);
    }, [isTouchDeviceLangDropdownOpen]);

    return (
        <Box mx={5}>
            <ClickAwayListener onClickAway={clickAwayHandler}>
                <div>
                    <Box
                        component={LanguageDropdownStyled}
                        onClick={selfClickHandler}
                        mb={10}
                        position="relative"
                        id={AUTOMATION_TESTING_IDS.languageSelect}
                    >
                        {!isMobile && (
                            <PopperContainer anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
                                <Box maxHeight={212} className="lang-menu" marginY={1} width={1}>
                                    <LanguagesList
                                        theme={theme}
                                        selectedLanguageKey={selectedLanguage.key}
                                        onSelect={handleLanguageSelect}
                                    />
                                </Box>
                            </PopperContainer>
                        )}

                        {isTouchDeviceLangDropdownOpen && (
                            <Portal container={document.body}>
                                <Box
                                    position="fixed"
                                    width={1}
                                    height={1}
                                    top={0}
                                    left={0}
                                    right={0}
                                    bottom={0}
                                    zIndex={2001}
                                    display="flex"
                                    alignItems="bottom"
                                >
                                    <Box
                                        position="relative"
                                        top={0}
                                        left={0}
                                        right={0}
                                        bottom={0}
                                        width={1}
                                        height={1}
                                        zIndex={9}
                                        bgcolor={
                                            isDark
                                                ? hexToRgba('#17181A', 0.7)
                                                : hexToRgba('#EDEFF2', 0.9)
                                        }
                                        onClick={handleCloseMobileDropDown}
                                    />

                                    <Box
                                        position="absolute"
                                        width={1}
                                        height={1}
                                        maxHeight={526}
                                        right={0}
                                        left={0}
                                        bottom={0}
                                        zIndex={19}
                                        display="flex"
                                        flexDirection="column"
                                        bgcolor={
                                            isDark
                                                ? theme.palette.grey.dark[600]
                                                : theme.palette.common.white
                                        }
                                    >
                                        <Box className="mobile-upper-section" p={5}>
                                            <Box display="flex" justifyContent="space-between">
                                                <Typography
                                                    color={isDark ? '#E0E3EA' : '#45474D'}
                                                    variant="p16"
                                                >
                                                    {t(
                                                        'common_left_sidebar_select_language_text',
                                                        'Select Language',
                                                    )}
                                                </Typography>

                                                <Box onClick={handleCloseMobileDropDown}>
                                                    <Typography
                                                        color={
                                                            isDark
                                                                ? '#8A8F99'
                                                                : theme.palette.lightMode.grey[900]
                                                        }
                                                        variant="p16"
                                                    >
                                                        {t(
                                                            'common_close_left_sidebar_select_language_text',
                                                            'Close',
                                                        )}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>

                                        <Box overflow="scroll" height={1}>
                                            <LanguagesList
                                                theme={theme}
                                                selectedLanguageKey={selectedLanguage.key}
                                                onSelect={handleLanguageSelect}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Portal>
                        )}

                        <Box
                            px={5}
                            border={1}
                            className="cursor-pointer"
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            borderColor={
                                isDark ? hexToRgba(theme.palette.common.white, 0.3) : '#D2D4D9'
                            }
                        >
                            <Box py={2} display="flex">
                                <Box height={20}>
                                    <Box
                                        component="img"
                                        src={selectedLanguage.flag}
                                        alt="flag"
                                        className="WP-LanguageDropDown-select-flag"
                                    />
                                </Box>

                                <Box
                                    component={Typography}
                                    ml={3}
                                    fontFamily={theme.typography.font.inter}
                                    variant="p14"
                                    color="#8b919b"
                                >
                                    {selectedLanguage.name}
                                </Box>
                            </Box>

                            <Box
                                component="img"
                                mr={1}
                                src={arrowDown}
                                alt="arrow-down"
                                className={`WP-LanguageDropdown-arrow ${anchorEl && 'rotate'}`}
                            />
                        </Box>
                    </Box>
                </div>
            </ClickAwayListener>
        </Box>
    );
};

LanguagesList.propTypes = {
    theme: PropTypes.object.isRequired,
    selectedLanguageKey: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
};

LanguageDropdown.propTypes = {
    onClickAway: PropTypes.func,
    setIsTouchDeviceLangDropdownOpen: PropTypes.func,
    onLeftSideBarClose: PropTypes.func,
    isTouchDeviceLangDropdownOpen: PropTypes.bool,
};

export default LanguageDropdown;
