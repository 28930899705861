import { useIsomorphicLayoutEffect } from '.';

export const useCssViewPoint = () => {
    const setupViewPoint = () => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    useIsomorphicLayoutEffect(() => {
        setupViewPoint();

        window.addEventListener('resize', setupViewPoint);
        return () => window.removeEventListener('resize', setupViewPoint);
    }, []);
};
