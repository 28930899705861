import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { hexToRgba } from 'src/helpers/styleHelpers';

import Authorize from '../../../../assets/images/desktop-logo.png';
import { titanAnalyticsEvents } from '../../../../helpers/titanAnalytics';
import { useTranslation } from '../../../../hooks';
import { removeSessionStorage } from '../../../../services';
import { globalSel } from '../../../../store/global';
import { translationsSel } from '../../../../store/translation';
import BorderedContent from '../../BorderedContent/BorderedContent';
import LoginWithScopely from '../../LoginWithScopely/LoginWithScopely';
import TextSuspend from '../../TextSuspend/TextSuspend';
import { Box } from '../../ui-components/Box/Box';
import Modal from '../Modal';
import ModalBody from '../ModalBody/ModalBody';

const types = {
    storeAuth: {
        title: {
            key: 'common_login_modal_title_text',
            fallback: 'Login With Your Scopely ID to Visit the Store',
        },
    },
    eventsAuth: {
        title: {
            key: 'common_events_login_modal_title_text',
            fallback: 'Sign in with Scopely to play the events',
        },
    },
    redeemAuth: {
        title: {
            key: 'common_redeem_login_modal_title_text',
            fallback: 'Sign in with Scopely to receive rewards',
        },
    },
};

const AuthorizeModalBody = ({ onClose, preview, type }) => {
    const t = useTranslation();

    const { common: translationsLoading } = useSelector(translationsSel.isLoadingSelector);
    const {
        palette,
        palette: { mode },
    } = useSelector(globalSel.themeSelector);

    const colors = {
        outerBorder: mode === 'dark' ? hexToRgba(palette.common.white, 0.2) : palette.common.white,
        innerBorder: mode === 'dark' ? hexToRgba(palette.common.white, 0.12) : palette.common.white,
        innerBgColor: palette.common.white,
        outerBgColor: palette.white.transparent10,
    };

    return (
        <ModalBody
            image={
                <BorderedContent
                    outerBorder={colors.outerBorder}
                    innerBorder={colors.innerBorder}
                    innerBgColor={colors.innerBgColor}
                    outerBgColor={colors.outerBgColor}
                    borderRadius={['100px', '100px']}
                >
                    <Box display="flex" alignItems="center" justifyContent="center" py={[5, 7]}>
                        <Box
                            component="img"
                            src={Authorize}
                            alt="sign-in"
                            width={[48, 60]}
                            height={[56, 70]}
                        />
                    </Box>
                </BorderedContent>
            }
            title={
                <TextSuspend loading={translationsLoading}>
                    {t(types[type]?.title.key, types[type].title.fallback)}
                </TextSuspend>
            }
            button={<LoginWithScopely isModalType disabled={preview} />}
            onClose={onClose}
        />
    );
};

const AuthorizeModal = ({ isOpen, onClose, preview, type = 'storeAuth' }) => {
    const handleClose = () => {
        removeSessionStorage(process.env.REACT_APP_AUTH_SUCCESS_REDIRECT);

        removeSessionStorage(process.env.REACT_APP_AUTH_SUCCESS_REDIRECT_TO_OFFER);
        onClose();
    };

    useEffect(() => {
        if (isOpen) {
            titanAnalyticsEvents.generateModalEvent({
                screen_id: 'auth',
                screen_category: 'authorization',
                interaction_object: 'authorization_modal',
            });
        }
    }, [isOpen]);

    if (preview) {
        return <AuthorizeModalBody preview type={type} />;
    }

    return (
        <Modal
            labelledBy="authorization dialog"
            describedBy="need to authorized"
            isOpen={isOpen}
            handleCloseModal={handleClose}
        >
            <AuthorizeModalBody onClose={handleClose} type={type} />
        </Modal>
    );
};

AuthorizeModal.defaultProps = {
    preview: false,
    onClose: () => {},
};

AuthorizeModalBody.propTypes = {
    preview: PropTypes.bool,
    onClose: PropTypes.func,
    type: PropTypes.oneOf(['storeAuth', 'eventsAuth', 'redeemAuth']),
};

AuthorizeModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    preview: PropTypes.bool,
    type: PropTypes.oneOf(['storeAuth', 'eventsAuth', 'redeemAuth']),
};

export default AuthorizeModal;
