import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type TLanguageKeys } from 'src/@types/store';

import { type TTranslations } from './../../@types/store/translation';
import { initialTranslationsState } from './initialState';

export const translationsSlice = createSlice({
    name: 'translations',
    initialState: initialTranslationsState,
    reducers: {
        setLanguage(state, action: PayloadAction<TLanguageKeys>) {
            state.selectedLanguage = action.payload;
        },
        setTranslationsKeys(state, action: PayloadAction<TTranslations>) {
            state.translations = {
                ...state.translations,
                ...action.payload,
            };
        },
        setLoading(state, action: PayloadAction<{ page: string; loading: boolean }>) {
            state.loading[action.payload.page] = action.payload.loading;
        },
    },
});
