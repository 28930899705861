import React, { useEffect } from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from '@mui/material';
import { type Theme } from '@mui/material';
import { type TIcon } from 'src/@types';
import { getMobileOperatingSystem } from 'src/helpers/userDeviceHelpers';
import { createGameDownloadLinks } from 'src/helpers/utils';

import { attributionHook } from '../../../../configs/web-sdk.config';
import { AUTOMATION_TESTING_IDS } from '../../../../constants';
import { downloadGameButtons } from '../../../../helpers/DownloadGameButtons';
import { titanAnalyticsEvents } from '../../../../helpers/titanAnalytics';
import { useTranslation } from '../../../../hooks';
import { translationsSel } from '../../../../store/translation/selectors';
import DownloadGameButton from '../../../components/DownloadGameButton/DownloadGameButton';
import TextSuspend from '../../../components/TextSuspend/TextSuspend';
import { Box } from '../../../components/ui-components/Box/Box';
import { Icon } from '../../../components/ui-components/Icon/Icon';
import { Typography } from '../../../components/ui-components/Typography/Typography';
import LanguageDropdown from './LanguageDropdown/LanguageDropdown';
import { LeftSidebarFooterStyled } from './LeftSidebarFooter.styled';

type TPage = {
    key: string;
    translationKey: string;
    href?: string;
    id: string;
    type: 'link' | 'cookie-button';
};

type TSocialLink = {
    href: string;
    id: string;
    icon: TIcon;
};

interface IProps {
    theme: Theme;
    isTouchDeviceLangDropdownOpen: boolean;

    setIsTouchDeviceLangDropdownOpen: (arg0: boolean) => void;
    onLeftSideBarClose: () => void;
}

const pages: TPage[] = [
    {
        key: 'Terms of use',
        translationKey: 'common_left_sidebar_terms_of_service_text',
        href: 'https://scopely.com/tos/',
        id: AUTOMATION_TESTING_IDS.termsOfUsePageLink,
        type: 'link',
    },
    {
        key: 'Privacy policy',
        translationKey: 'common_left_sidebar_privacy_policy_text',
        href: 'https://scopely.com/privacy/',
        id: AUTOMATION_TESTING_IDS.privacyPolicyPageLink,
        type: 'link',
    },
    {
        key: 'Cookie Settings',
        translationKey: 'common_left_sidebar_cookie_settings_text',
        id: AUTOMATION_TESTING_IDS.manageCookieSettings,
        type: 'cookie-button',
    },
];

const socialLinks: TSocialLink[] = [
    {
        href: 'https://www.facebook.com/StarTrekFleetCommand',
        id: AUTOMATION_TESTING_IDS.fbPageLink,
        icon: 'icon-facebook',
    },
    {
        href: 'https://www.youtube.com/channel/UCaPdLI1d-6LveN5w0kmtroA',
        id: AUTOMATION_TESTING_IDS.youtubePageLink,
        icon: 'icon-youtube',
    },
    {
        href: 'https://twitter.com/StarTrekFleet',
        id: AUTOMATION_TESTING_IDS.twitterPageLink,
        icon: 'icon-twitter',
    },
    {
        href: 'https://www.instagram.com/startrekfleetcommand/',
        id: AUTOMATION_TESTING_IDS.instagramPageLink,
        icon: 'icon-instagram',
    },
    {
        href: 'http://discord.gg/stfc',
        id: AUTOMATION_TESTING_IDS.discordPageLink,
        icon: 'icon-discord',
    },
];

const texts = {
    copyright: {
        key: 'common_left_sidebar_footer_copyright',
        fallback:
            '© 2022 CBS Broadcasting Inc. and Paramount Pictures Corporation. All Rights Reserved. Game Software © 2022 Scopely, Inc. All Rights Reserved.',
    },
};

const LeftSidebarFooter: React.FC<IProps> = ({
    theme,
    setIsTouchDeviceLangDropdownOpen,
    onLeftSideBarClose,
    isTouchDeviceLangDropdownOpen,
}) => {
    const { common: translationsLoading } = useSelector(translationsSel.isLoadingSelector);
    const selectedLang = useSelector(translationsSel.selectedLanguageSelector);

    const t = useTranslation();

    const notMobileOs = getMobileOperatingSystem() === 'unknown';
    const isDark = theme.palette.mode === 'dark';

    const mobileDownloadButtons = useMemo(() => {
        return [
            {
                href: createGameDownloadLinks().iOS,
                logo: downloadGameButtons[`${selectedLang}_iOS_black`],
                logoLight: downloadGameButtons[`${selectedLang}_iOS_white`],
                alt: 'AppStore',
                cb: () => {
                    titanAnalyticsEvents.generateButtonEvent({
                        screen_id: 'download_the_ios_game',
                        screen_category: 'game',
                        interaction_object: 'download_the_ios_game_button',
                    });
                    attributionHook.download();
                },
                id: AUTOMATION_TESTING_IDS.iOSMarketLink,
                className: 'ios-market-link',
            },
            {
                href: createGameDownloadLinks().android,
                logo: downloadGameButtons[`${selectedLang}_android_black`],
                logoLight: downloadGameButtons[`${selectedLang}_android_white`],
                alt: 'PlayMarket',
                cb: () => {
                    titanAnalyticsEvents.generateButtonEvent({
                        screen_id: 'download_the_android_game',
                        screen_category: 'game',
                        interaction_object: 'download_the_android_game_button',
                    });
                    attributionHook.download();
                },
                id: AUTOMATION_TESTING_IDS.androidMarketLink,
                className: 'android-market-link',
            },
        ];
    }, [selectedLang]);

    useEffect(() => {
        if (window.reloadOTBanner) {
            window.reloadOTBanner();
        }
    }, []);

    return (
        <Box component={LeftSidebarFooterStyled} pb={8}>
            <Box px={5} py={13} className="WP-LeftSideBarFooter-download-links">
                {notMobileOs && <DownloadGameButton />}

                {mobileDownloadButtons.map((version, index) => (
                    <Box mb={index === 1 ? 0 : 5} key={index}>
                        <Box
                            component={Link}
                            href={version.href}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={version.cb}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            border={1}
                            borderColor={isDark ? '#49494d' : theme.palette.lightMode.grey[500]}
                            bgcolor={
                                isDark ? theme.palette.common.black : theme.palette.common.white
                            }
                            borderRadius="4px"
                            p={1}
                            height={46}
                            id={version.id}
                            className={`WP-LeftSideBarFooter-download-link ${version.className}`}
                        >
                            <Box
                                component="img"
                                src={isDark ? version.logo : version.logoLight}
                                alt={version.alt}
                            />
                        </Box>
                    </Box>
                ))}
            </Box>

            <hr />

            <Box pt={13} pb={8} mx={5}>
                {pages.map((page, index) =>
                    page.type === 'cookie-button' ? (
                        <Box
                            key={index}
                            mt={index === 0 ? 0 : 5}
                            display="flex"
                            id="ot-sdk-link"
                            tabindex="0"
                            className="ot-sdk-show-settings WP-LeftSidebar-link cursor-pointer"
                        >
                            <Typography
                                color={
                                    theme.palette.mode === 'dark'
                                        ? '#8A8F99'
                                        : theme.palette.lightMode.grey[900]
                                }
                                variant="p14"
                            >
                                <TextSuspend loading={translationsLoading}>
                                    Cookies Settings
                                </TextSuspend>
                            </Typography>
                        </Box>
                    ) : (
                        <Box key={index} mt={index === 0 ? 0 : 5} display="flex">
                            <Link
                                href={page.href}
                                target="_blank"
                                rel="noopener noreferrer"
                                underline="none"
                                id={page.id}
                            >
                                <Typography
                                    color={
                                        theme.palette.mode === 'dark'
                                            ? '#8A8F99'
                                            : theme.palette.lightMode.grey[900]
                                    }
                                    variant="p14"
                                    className="cursor-pointer"
                                >
                                    <TextSuspend loading={translationsLoading}>
                                        {t(page.translationKey, page.key)}
                                    </TextSuspend>
                                </Typography>
                            </Link>
                        </Box>
                    ),
                )}
            </Box>

            <Box display="flex" alignItems="center" mb={10} px={5} className="social-links">
                {socialLinks.map((link, index) => (
                    <Link
                        key={index}
                        href={link.href}
                        underline="none"
                        target="_blank"
                        rel="noopener noreferrer"
                        id={link.id}
                    >
                        <Icon className={link.icon} />
                    </Link>
                ))}
            </Box>

            <LanguageDropdown
                setIsTouchDeviceLangDropdownOpen={setIsTouchDeviceLangDropdownOpen}
                onLeftSideBarClose={onLeftSideBarClose}
                isTouchDeviceLangDropdownOpen={isTouchDeviceLangDropdownOpen}
            />

            <Box mx="auto" px={5}>
                <Typography color="#8A8F99" variant="p12">
                    <span id={AUTOMATION_TESTING_IDS.copyrightTextTitle}>
                        {t(texts.copyright.key, texts.copyright.fallback)}
                    </span>
                </Typography>
            </Box>
        </Box>
    );
};

export default LeftSidebarFooter;
