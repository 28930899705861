import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';

import { AUTOMATION_TESTING_IDS } from '../../../constants';
import { titanAnalyticsEvents } from '../../../helpers/titanAnalytics';
import { setSessionStorage } from '../../../services';
import { globalSel } from '../../../store/global';
import { globalOp } from '../../../store/global';
import AppLogo from '../../components/AppLogo/AppLogo';
import { Box } from '../../components/ui-components/Box/Box';
import { Icon } from '../../components/ui-components/Icon/Icon';
import { LeftSidebarStyled } from './LeftSidebar.styled';
import LeftSidebarFooter from './LeftSidebarFooter/LeftSidebarFooter';
import Nav from './Nav/Nav';

const navigationList = [
    {
        id: 1,
        translationKey: 'common_left_sidebar_navigation_home_text',
        key: 'home',
        name: 'home',
        fallBackName: 'Home',
        href: '/',
        iconClassName: 'icon-dashboard-fill',
        checkForAuth: false,
    },
    {
        id: 2,
        translationKey: 'common_left_sidebar_navigation_store_text',
        key: 'store',
        name: 'store',
        fallBackName: 'Store',
        href: '/store',
        iconClassName: 'icon-shopping-bag-fill',
        checkForAuth: true,
    },
    {
        id: 3,
        translationKey: 'common_left_sidebar_navigation_events_text',
        key: 'events',
        name: 'events',
        fallBackName: 'Events',
        href: '/events',
        iconClassName: 'icon-medal-fill',
        checkForAuth: true,
    },
    {
        id: 5,
        translationKey: 'common_left_sidebar_navigation_redeem_code_text',
        key: 'redeem-code',
        name: 'redeem-code',
        fallBackName: 'Redeem Code',
        href: '/redeem-code',
        iconClassName: 'icon-redeem',
        checkForAuth: true,
    },
    {
        id: 6,
        translationKey: 'common_left_sidebar_navigation_news_text',
        key: 'news',
        name: 'news',
        fallBackName: 'News',
        href: '/news',
        iconClassName: 'icon-article-fill',
        checkForAuth: false,
    },
];

const LeftSidebar = ({ openLeftSidebar, setOpenLeftSidebar }) => {
    const theme = useSelector(globalSel.themeSelector);
    const [isTouchDeviceLangDropdownOpen, setIsTouchDeviceLangDropdownOpen] = useState(false);
    const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

    const leftSideBarRef = useRef(null);

    const dispatch = useDispatch();

    const handleOpenModal = (href) => {
        if (href === '/store' || href === '/events' || href === '/redeem-code') {
            setSessionStorage(process.env.REACT_APP_AUTH_SUCCESS_REDIRECT, href);
        }

        dispatch(
            globalOp.handleAuthenticatedModalToggle({
                open: true,
                type:
                    href === '/events'
                        ? 'eventsAuth'
                        : href === '/redeem-code'
                          ? 'redeemAuth'
                          : 'storeAuth',
            }),
        );
    };

    const handleLogoClick = () => {
        titanAnalyticsEvents.generateButtonEvent({
            screen_id: 'navigation',
            screen_category: 'navigation',
            interaction_object: 'navigation_button',
        });

        setOpenLeftSidebar(false);
        dropScrollPosition();
    };

    const dropScrollPosition = () => {
        leftSideBarRef.current?.scrollTo(0, 0);
    };

    const handleLeftSidebar = () => {
        setOpenLeftSidebar(false);
    };

    return (
        <LeftSidebarStyled
            isMobileDropdown={isTouchDeviceLangDropdownOpen}
            className={openLeftSidebar && 'show'}
            ref={leftSideBarRef}
        >
            <Box display="flex">
                <AppLogo isLeftSideBar onClick={handleLogoClick} />

                <Box
                    display={mdUp ? 'none' : 'block'}
                    component={Icon}
                    size={12}
                    color="#8A8F99"
                    height={12}
                    mt={9}
                    className="icon-close cursor-pointer"
                    onClick={handleLeftSidebar}
                    id={AUTOMATION_TESTING_IDS.leftSideBarCloseButton}
                />
            </Box>
            <Nav
                navigationList={navigationList}
                setOpenLeftSidebar={setOpenLeftSidebar}
                onModalOpen={handleOpenModal}
                dropScrollPosition={dropScrollPosition}
            />
            <hr />
            <LeftSidebarFooter
                theme={theme}
                setIsTouchDeviceLangDropdownOpen={setIsTouchDeviceLangDropdownOpen}
                onLeftSideBarClose={setOpenLeftSidebar}
                isTouchDeviceLangDropdownOpen={isTouchDeviceLangDropdownOpen}
            />
        </LeftSidebarStyled>
    );
};

LeftSidebar.propTypes = {
    openLeftSidebar: PropTypes.bool,
    setOpenLeftSidebar: PropTypes.func,
};

export default LeftSidebar;
