import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import {
    type IBucket,
    type IBundle,
    type IStoreGroup,
    type TContentItem,
    type TOfferPreviewModal,
    type TPaymentStatusModal,
} from './../../@types/store/offer';
import { initialOfferState } from './initialState';

type TClaimableItemsModal = {
    items: TContentItem[];
    bundleId: number;
    quantity: number;
};

export const offerSlice = createSlice({
    name: 'offer',
    initialState: initialOfferState,
    reducers: {
        //todo not used
        setLoading(state, action: PayloadAction<boolean>) {
            state.isOfferLoading = action.payload;
        },
        setOfferItems(state, action: PayloadAction<IBundle[]>) {
            state.offerItems = action.payload;
        },
        setSecondaryOfferItems(state, action: PayloadAction<object>) {
            state.secondaryOfferItems = action.payload;
        },
        setOfferBanner(state, action: PayloadAction<null | IBundle>) {
            state.offerBanner = action.payload;
        },
        setPaymentStatus(state, action: PayloadAction<null | 'done'>) {
            state.paymentStatus = action.payload;
        },
        setContentItemData(state, action: PayloadAction<{ bundleId: number; itemData: object }>) {
            state.contentItemData[action.payload.bundleId] = action.payload.itemData;
        },
        setContentItemsData(state, action: PayloadAction<{ bundleId: number; itemData: object }>) {
            state.contentItemData[action.payload.bundleId] = {
                ...state.contentItemData[action.payload.bundleId],
                ...action.payload.itemData,
            };
        },
        setContentItemLoading(state, action: PayloadAction<number>) {
            state.contentItemLoading[action.payload] = !state.contentItemLoading[action.payload];
        },
        setOfferPurchasing(state, action: PayloadAction<boolean>) {
            state.isOfferPurchasing = action.payload;
        },
        setLocalizedItems(state, action: PayloadAction<object>) {
            state.localizedItems = action.payload;
        },
        setProcessingBundle(state, action: PayloadAction<IBundle | null>) {
            state.processingBundle = action.payload;
        },
        setWebGifts(state, action: PayloadAction<IBundle[]>) {
            state.webGifts = action.payload;
            // state.processingId = null;
            // state.isOfferPurchasing = false;
        },
        setClaimableWebGiftsCount(state, action: PayloadAction<number>) {
            state.claimableWebGiftsCount = action.payload;
        },
        setClaimableItemsModalPreview(state, action: PayloadAction<TClaimableItemsModal>) {
            state.webGiftsSection.claimedItems = {
                isModalOpen: !!action.payload.items.length,
                items: action.payload.items,
                bundleId: action.payload.bundleId,
                quantity: action.payload.quantity,
            };
        },
        setIsWebGiftsLoading(state, action: PayloadAction<boolean>) {
            state.isWebGiftsLoading = action.payload;
        },
        setOfferBannerQuantity(
            state,
            action: PayloadAction<{ bundleId: number; quantity: number }>,
        ) {
            state.offerBannerQuantity[action.payload.bundleId] = action.payload.quantity;
        },
        clearOfferBannerQuantity(state) {
            state.offerBannerQuantity = {};
        },
        setBoughtBundleId(state, action: PayloadAction<null | number>) {
            state.boughtBundleId = action.payload;
        },
        setOfferPreviewModal(state, action: PayloadAction<TOfferPreviewModal>) {
            state.offerSection.modals.offerPreview = {
                isModalOpen: action.payload.isModalOpen,
                bundle: action.payload.bundle,
            };
        },
        setPaymentStatusModal(state, action: PayloadAction<TPaymentStatusModal>) {
            state.offerSection.modals.paymentStatus = {
                type: action.payload.type,
                isModalOpen: action.payload.isModalOpen,
            };
        },
        setStoreGroupsItems(state, action: PayloadAction<IStoreGroup[]>) {
            state.storeGroups = action.payload;
        },
        setSelectedStoreGroup(state, action: PayloadAction<IStoreGroup | null>) {
            state.selectedStoreGroup = action.payload;
        },
        setSelectedBucket(state, action: PayloadAction<null | IBucket>) {
            state.selectedBucket = action.payload;
        },
        setStoreGroupsTransitioning(state, action: PayloadAction<boolean>) {
            state.isStoreGroupsTransitioning = action.payload;
        },
        setLastTimeOffersFetched(state, action: PayloadAction<number | null>) {
            state.lastDataFetchTimestamp = action.payload;
        },
        //todo not used
        // setPurchasedBundle(state, action) {
        //     state.offerSection.purchasedBundle = {
        //         data: action.payload.data,
        //         quantity: action.payload.quantity,
        //     };
        // },
        clearOfferItems(state) {
            state.contentItemLoading = {};
            state.contentItemData = {};
            state.offerItems = [];
            state.offerBanner = null;
            state.isOfferLoading = true;
            // state.processingId = null;
            //todo not used
        },
    },
});
