const setCookie = (key: string, val: string) => {
    document.cookie = `${key}=${val}; path=/`;
};

const getCookie = (key: string) => {
    const searchedCookie = document.cookie.split(';').find((c) => c.trim().startsWith(`${key}=`));

    if (searchedCookie) {
        return searchedCookie.split('=')[1];
    }

    return '';
};

const removeCookie = (key: string) => {
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

const getAllCookies = () => {
    return document.cookie.split(';').map((c) => c.trim());
};

const getCookieStatus = () => {
    return navigator.cookieEnabled;
};

export { setCookie, getCookie, getAllCookies, getCookieStatus, removeCookie };
