import { getUserSelectedLanguage } from 'src/helpers/webEnvHelpers';

const PLATFORM_ID = 'scopely_platform_20210125150341102-16a8629609ae8cd';
const DOMAIN = 'scopely';
const LANGUAGE = getUserSelectedLanguage();

export default function helpShift(language: string) {
    window.helpshiftConfig = {
        platformId: PLATFORM_ID,
        domain: DOMAIN,
        language: language ? language : LANGUAGE,
    };

    const t = document;
    const e = 'hs-chat';

    if ('function' != typeof window.Helpshift) {
        const n: { (): void; q: any[] } = () => {
            // eslint-disable-next-line prefer-rest-params
            n.q.push(arguments);
        };

        n.q = [];
        window.Helpshift = n;
        const a = t.getElementsByTagName('script')[0] || t.getElementById('root');

        if (t.getElementById(e)) return;

        const i = t.createElement('script') as HTMLScriptElement | any;
        i.async = !0;
        i.id = e;
        i.src = 'https://webchat.helpshift.com/webChat.js';

        const o = function () {
            window.Helpshift('init');
        };

        window.attachEvent ? i.attachEvent('onload', o) : i.addEventListener('load', o, !1);
        a?.parentNode?.insertBefore(i, a);
    } else window.Helpshift('updateHelpshiftConfig');
}
