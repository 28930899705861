import { useEffect, useLayoutEffect, useState } from 'react';

const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

// TODO not used
export function useWindowScroll() {
    const [windowScrollY, setWindowScrollY] = useState(0);

    useIsomorphicLayoutEffect(() => {
        const handleWindowScroll = () => {
            setWindowScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleWindowScroll);

        return () => window.removeEventListener('scroll', handleWindowScroll);
    }, []);
    return windowScrollY;
}
