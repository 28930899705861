import { useEffect, useState } from 'react';

import { type TOfferItem } from '../@types/hooks/hooks';

export const useOfferDetailsFilter = ({ itemData, content }) => {
    const [filteredItems, setFilteredItems] = useState<TOfferItem[]>([]);

    useEffect(() => {
        if (itemData && Array.isArray(content)) {
            setFilteredItems(content.filter((item) => itemData[item.id]));
        }
    }, [itemData, content]);

    return filteredItems;
};
