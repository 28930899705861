import { BrowserTracker, BuildType, Settings } from '@scopely/web-sdk-analytics';
import {
    PushNotificationService,
    Settings as NotificationSettings,
} from '@scopely/web-sdk-push-notifications';
import { Config, OktaProviderConfig, ScopelyId } from '@scopely/web-sdk-scopelyid';
import { AttributionHooks } from '@scopely/web-sdk-ua';

import { API_ENV, TITAN_API_KEY, VAPID_PUB_KEY } from '../constants';
import * as Constants from '../constants';
import { type IModifiedTracker, type ITitan } from './../@types/configs/web-sdk.config';

const debug = API_ENV !== 'prod';
const buildType = API_ENV === 'prod' ? BuildType.Production : BuildType.Debug;
const settings = new Settings(TITAN_API_KEY || '', buildType)
    .withDebug(debug)
    .withFlushIntervalMillis(5000);

const tracker: IModifiedTracker = new BrowserTracker(settings);
tracker.register();

const Titan: ITitan = {
    SendEvent: () => {
        return;
    },
};

Titan.SendEvent = function (event) {
    if (event.name && event.parameters) {
        tracker.track(event.name, event.parameters);
        window?.DD_RUM?.addAction('titan-events-count', {
            event: event.name,
            params: event.parameters,
        });
    } else {
        // eslint-disable-next-line no-console
        console.error('Trying to send malformed event: ' + JSON.stringify(event));
    }
};

const attributionHook = new AttributionHooks(tracker);

const scopelyIdConfig = new Config(
    new OktaProviderConfig(
        Constants.SCOPELY_LOGIN_ENDPOINT || '',
        Constants.SCOPELY_LOGIN_CLIENT_ID || '',
        Constants.APP_REDIRECT_URI || '',
        Constants.APP_REDIRECT_URI || '',
    ),
    'https://heimdall.aprod.scopely.io',
);

const scopelyId = new ScopelyId(scopelyIdConfig, tracker);

// See Service Worker section for more details.
const pathToServiceWorker = 'sw.js';
const notificationSettings = new NotificationSettings(pathToServiceWorker, VAPID_PUB_KEY || '');
const service = PushNotificationService.browser(notificationSettings, tracker);

export { Titan, tracker, scopelyId, attributionHook, service };
