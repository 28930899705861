import { useIsomorphicLayoutEffect } from '.';

type TArguments = {
    when: boolean | (() => boolean);
    message: string;
};

export const useBeforeUnload = ({ when, message }: TArguments) => {
    useIsomorphicLayoutEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent) => {
            event.preventDefault();
            event.returnValue = message;
            return message;
        };

        if (when) {
            window.addEventListener('beforeunload', handleBeforeUnload);
        }

        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, [when, message]);
};
