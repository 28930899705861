import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { checkBrowser, getMobileOperatingSystem } from 'src/helpers/userDeviceHelpers';
import { redirectToScopelyAuth } from 'src/helpers/utils';

import mobileLogo from '../../../assets/images/mobile-logo.png';
import * as Constants from '../../../constants';
import { useTranslation } from '../../../hooks';
import globalSlice from '../../../store/global';
import { globalSel } from '../../../store/global';
import { translationsSel } from '../../../store/translation/selectors';
import TextSuspend from '../TextSuspend/TextSuspend';
import { Box } from '../ui-components/Box/Box';
import { Button } from '../ui-components/Button/Button';
import { Typography } from '../ui-components/Typography/Typography';
import { LoginWithScopelyStyled } from './LoginWithScopelyStyled';

const LoginWithScopely = ({ isModalType, disabled }) => {
    const dispatch = useDispatch();
    const t = useTranslation();

    const isLoading = useSelector(globalSel.isLoadingSelector);
    const { common: translationsLoading } = useSelector(translationsSel.isLoadingSelector);
    const { setLoading } = globalSlice.actions;

    const checkForCache = () => {
        requestAnimationFrame(() => {
            dispatch(setLoading(false));
        });
    };

    const handleRedirectToLogin = () => {
        dispatch(setLoading(true));
        redirectToScopelyAuth();

        const os = getMobileOperatingSystem();
        const isSamsungInternet = checkBrowser.isSamsungInternet();
        const isSafari = checkBrowser.isSafari();

        if (os === 'iOS' || isSamsungInternet || isSafari) {
            window.addEventListener('visibilitychange', checkForCache);
        }
    };

    useEffect(() => {
        return () => {
            window.removeEventListener('visibilitychange', checkForCache);
        };
    }, []);

    return (
        <LoginWithScopelyStyled isModalType={isModalType}>
            <Button
                isLoading={isLoading}
                color="custom"
                onClick={handleRedirectToLogin}
                className={clsx('WP-LoginWithScopely-button', {
                    loading: isLoading,
                })}
                id={Constants.AUTOMATION_TESTING_IDS.logInButton}
                disabled={disabled}
            >
                {isLoading ? (
                    <CircularProgress
                        size={21}
                        thickness={4.5}
                        className="WP-LoginWithScopely-circularProgress"
                    />
                ) : (
                    <Box
                        component="img"
                        src={mobileLogo}
                        alt="logo"
                        mr={2}
                        width={16}
                        height={22}
                    />
                )}

                <Typography variant={isModalType ? 'p16' : 'p14'} fWeight="medium">
                    <TextSuspend loading={translationsLoading} customVariant="#1D33A5">
                        {t('common_header_login_button_text', 'Login via ScopelyID')}
                    </TextSuspend>
                </Typography>
            </Button>
        </LoginWithScopelyStyled>
    );
};

LoginWithScopely.propTypes = {
    disabled: PropTypes.bool,
    isModalType: PropTypes.bool,
};

export default LoginWithScopely;
