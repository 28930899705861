import React, { useLayoutEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import { typography } from '../../../../theme/typography/typography';
import { Box } from '../Box/Box';
import { Typography } from '../Typography/Typography';
import { SimpleVariantStyled } from './SimpleVariant.styled';

interface SimpleVariantProps {
    text?: string | React.ReactNode;
    maxRows?: number;
    color?: string;
    textShadow?: string;
    fWeight?: 'bold' | 'semiBold' | 'medium' | 'regular' | 'book';
    tooltipWidth?: string;
    tooltipTextColor?: string;
    tooltipBGColor?: string;
    tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
    tooltipBorderRadius?: string;
    tooltipBorderColor?: string;
    variant?: string;
    arrowSize?: string;
    centerX?: boolean;
    centerY?: boolean;
    moveX?: string;
    moveY?: string;
    zIndex?: number;
    [otherProps: string]: any;
}

const SimpleVariant: React.FC<SimpleVariantProps> = ({
    maxRows = 1,
    text,
    tooltipWidth = 'auto',
    tooltipTextColor = '#fff',
    tooltipBGColor = 'rgb(34, 36, 38)',
    tooltipPosition = 'top',
    tooltipBorderRadius = '6px',
    tooltipBorderColor = '#45474d',
    arrowSize = '5px',
    centerX = false,
    centerY = false,
    moveX = '0px',
    moveY = '0px',
    zIndex = 3,
    variant = 'p12',
    color = 'black',
    ...restTextParams // color, fWeight, textShadow ...
}) => {
    const [lineHeight, setLineHeight] = useState<number | null>(null);
    const [offsetHeight, setOffsetHeight] = useState<number | null>(null);

    const elementRef = useRef<HTMLDivElement>(null);

    const haveProperties = lineHeight && offsetHeight;
    const isLong = haveProperties ? offsetHeight / lineHeight > maxRows : false;

    useLayoutEffect(() => {
        const el = elementRef.current!.firstChild as HTMLElement;
        const textLineHeight = parseInt(typography.size[variant].lineHeight);

        setLineHeight(textLineHeight);
        setOffsetHeight(el && el.offsetHeight);
    }, [variant]);

    return (
        <SimpleVariantStyled
            maxRows={maxRows}
            tooltipWidth={tooltipWidth}
            tooltipTextColor={tooltipTextColor}
            tooltipBGColor={tooltipBGColor}
            tooltipPosition={tooltipPosition}
            tooltipBorderRadius={tooltipBorderRadius}
            tooltipBorderColor={tooltipBorderColor}
            arrowSize={arrowSize}
            centerX={centerX}
            centerY={centerY}
            moveX={moveX}
            moveY={moveY}
            zIndex={zIndex}
            ref={elementRef}
        >
            {isLong ? (
                <Box className="WP-TruncateText-wrapper">
                    <Typography
                        {...restTextParams}
                        color={color}
                        variant={variant}
                        className={clsx(
                            restTextParams.className,
                            'WP-TruncateText-title-long break',
                        )}
                    >
                        {text}
                    </Typography>

                    <Typography
                        color={color}
                        variant={variant}
                        className="WP-TruncateText-tooltip-text"
                    >
                        {text}
                    </Typography>
                </Box>
            ) : (
                <Typography
                    {...restTextParams}
                    className={clsx(restTextParams.className, 'break')}
                    color={color}
                    variant={variant}
                >
                    {text}
                </Typography>
            )}
        </SimpleVariantStyled>
    );
};

export default SimpleVariant;
