import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSearchParams } from 'src/helpers/webEnvHelpers';
import { useAppDispatch } from 'src/store/configureStore';

import { scopelyId } from '../configs/web-sdk.config';
import { globalOp } from '../store/global';

export const useLogout = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = getSearchParams(window.location.search);

    useEffect(() => {
        const logout = async () => {
            if (params.get('state') && !params.get('code')) {
                const currentLocation = window.location.href;

                if (scopelyId.isLogoutUri(currentLocation)) {
                    const previousLocation = scopelyId.previousLocation();

                    if (previousLocation) navigate(previousLocation.pathname);

                    const logoutResult = await scopelyId.logoutResult(currentLocation);

                    if (!logoutResult.isSuccessful()) {
                        dispatch(
                            globalOp.handleOpenSnackbar({
                                open: true,
                                variant: 'error',
                                message: 'Logout error',
                                messageTranslationKey: 'common_snackbar_logout_error', // TODO add in CSV
                            }),
                        );
                    }
                }

                params.delete('state');
            }
        };
        logout();
    }, []);
};
