import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isAuthorizationUri } from 'src/helpers/utils';
import { useAppDispatch } from 'src/store/configureStore';

import { scopelyId } from '../configs/web-sdk.config';
import { globalOp, globalSel } from '../store/global';

export const useLogIn = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const isAuthenticated = useSelector(globalSel.isAuthSelector);

    const scopelyLogin = async () => {
        const currentLocation = window.location.href;

        if (isAuthorizationUri(currentLocation)) {
            const previousLocation = scopelyId.previousLocation();

            if (previousLocation) {
                navigate(previousLocation.pathname);
            }

            const authResult = await scopelyId.authorizationResult(currentLocation);

            if (authResult.isSuccessful()) {
                const accessToken = authResult.getResponse()!.getAccessToken();

                dispatch(globalOp.getAccessToken(accessToken));
                // trackLoginFunnel();
            }
        }
    };

    useEffect(() => {
        if (!isAuthenticated) {
            scopelyLogin();
        }
        // Do setup for non-authorizing "normal" app flow...
    }, [isAuthenticated]);

    return null;
};
