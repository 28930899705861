const setSessionStorage = (key: string, val: any) => {
    sessionStorage.setItem(key, JSON.stringify(val));
};

const getSessionStorage = (key: string) => {
    return JSON.parse(sessionStorage.getItem(key) || JSON.stringify(''));
};

const removeSessionStorage = (key: string) => {
    sessionStorage.removeItem(key);
};

export { setSessionStorage, getSessionStorage, removeSessionStorage };
