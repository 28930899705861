import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Hidden } from '@mui/material';
import { hexToRgba } from 'src/helpers/styleHelpers';
import { useAppDispatch } from 'src/store/configureStore';

import MaintenanceIcon from '../../../assets/images/Maintenance.png';
import { useTranslation } from '../../../hooks';
import { handleError } from '../../../services/handleError.service';
import { globalSel } from '../../../store/global';
import { translationsOp } from '../../../store/translation';
import { translationsSel } from '../../../store/translation/selectors';
import BorderedContent from '../../components/BorderedContent/BorderedContent';
import TextSuspend from '../../components/TextSuspend/TextSuspend';
import { Box } from '../../components/ui-components/Box/Box';
import { Typography } from '../../components/ui-components/Typography/Typography';
import { MaintenanceStyled } from './Maintenance.styled';

const backgroundElements = ['WP-Maintenance-officers_1', 'WP-Maintenance-officers_2'];

const mixedElements = ['WP-Maintenance-red-bg', 'WP-Maintenance-galaxy'];

interface IProps {
    fullPage?: boolean;
}

const Maintenance: React.FC<IProps> = ({ fullPage }) => {
    const dispatch = useAppDispatch();

    const {
        palette,
        palette: { mode },
    } = useSelector(globalSel.themeSelector);
    const { common: translationsLoading } = useSelector(translationsSel.isLoadingSelector);

    const { getTranslations, setTranslations, setTranslationLoading } = translationsOp;

    const t = useTranslation();

    const isDark = mode === 'dark';

    const colors = {
        outerBorder: isDark ? '#1F2122' : palette.common.white,
        bgColor: isDark ? hexToRgba('#1A1B1C', 0.8) : hexToRgba(palette.common.white, 0.8),
        titleColor: isDark ? '#E9EBF2' : '#2E3033',
        helpersTextColor: isDark ? '#D7DAE0' : palette.lightMode.grey[900],
    };

    useEffect(() => {
        handleError(
            getTranslations(['common'])
                .then((res) => {
                    if (Array.isArray(res)) {
                        dispatch(setTranslations(res[0]?.data));
                    }
                })
                .finally(() => {
                    dispatch(setTranslationLoading('common', false));
                }),
        );
    }, []);

    return (
        <Box
            component={MaintenanceStyled}
            fullPage={fullPage}
            position="relative"
            left={0}
            right={0}
            display="flex"
            alignItems="center"
            justifyContent="center"
            mx={!fullPage && [-5, -8]}
            height={1}
        >
            <Box position="absolute" top={0} left={0} right={0} mx={5} height={1}>
                {backgroundElements.map((className, index) => (
                    <Box
                        key={index}
                        position="absolute"
                        top={0}
                        left={0}
                        width={1}
                        height={1}
                        className={className}
                    />
                ))}
            </Box>

            {isDark &&
                mixedElements.map((className, index) => (
                    <Box
                        key={index}
                        position="absolute"
                        top={0}
                        left={0}
                        width={1}
                        height={1}
                        className={className}
                    />
                ))}

            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                position="relative"
                zIndex={9}
                px={[5, 8]}
                mt={21}
            >
                <BorderedContent
                    outerBorder={colors.outerBorder}
                    innerBgColor={colors.bgColor}
                    borderRadius={['100px', '100px']}
                    className="WP-BorderedContent-box"
                    width="auto"
                >
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        py={[4, 5]}
                        px={[3, 4]}
                    >
                        <Box
                            component="img"
                            src={MaintenanceIcon}
                            alt="sign-in"
                            width={[95, 109, 109, 132]}
                            height={[85, 98, 98, 118]}
                        />
                    </Box>
                </BorderedContent>

                <Box mt={[8, 10, 10, 11]} maxWidth={[320, 'none']} textAlign="center">
                    <Typography
                        variant={['p24', 'h1']}
                        color={colors.titleColor}
                        className="WP-Maintenance-title"
                    >
                        <TextSuspend loading={translationsLoading}>
                            {t('common_maintenance_title_text', 'Website Under Maintenance')}
                        </TextSuspend>
                    </Typography>

                    <Box
                        mt={[4, 4, 4, 5]}
                        component={Typography}
                        variant={['p14', 'p14', 'p14', 'p16', 'p18']}
                        color={colors.helpersTextColor}
                    >
                        <TextSuspend loading={translationsLoading}>
                            {t(
                                'common_maintenance_text_chunk1',
                                'The page you are looking for is currently under the maintenance.',
                            )}
                        </TextSuspend>

                        <Hidden smDown>
                            <br />
                        </Hidden>

                        <TextSuspend loading={translationsLoading}>
                            {t('common_maintenance_text_chunk2', 'We will be back soon.')}
                        </TextSuspend>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Maintenance;
