import React from 'react';
import { useSelector } from 'react-redux';

import { offerSel } from '../store/offer';

export const useHandleNavigationPrevent = () => {
    const offerPurchasingSelector = useSelector(offerSel.offerPurchasingSelector);

    const handleNavigationClick = (e: React.MouseEvent<HTMLElement>) => {
        if (offerPurchasingSelector) {
            e.preventDefault();
            return false;
        }

        return true;
    };

    return {
        handleNavigationClick,
    };
};
